import { createSlice } from '@reduxjs/toolkit';
import {
  ENROLLMENT_RESULT_CATEGORIES,
  ENROLLMENT_RESULT_IS_PAID_ALL_VALUE,
} from '../utils/constants';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';
import {
  VerifyPassword,
  updateCSVLoadingProgress,
  updateCSVLoadingTips,
  exportEnrollmentResultForm,
  loadEnrollmentPlayersResult,
  loadEnrollmentResultSummary,
  loadEnrollmentTeamsResult,
  loadEnrollmentDetailsById,
  exportEnrollmentNotTeamResultForm,
  removeEnrollmentResultItem,
} from './thunks';

const initialState = {
  enrollmentTitle: '',
  playersData: {
    items: [],
    pageSize: 10,
    pageNumber: 0,
    total: 0,
    end: true,
  },
  teamsData: {
    items: [],
    pageSize: 10,
    pageNumber: 0,
    total: 0,
    end: true,
  },
  categories: [],
  summaryTotal: 0,
  csvData: '',
  notTeamCSV: {
    data: '',
    status: API_REQUEST_STATUS.IDLE,
  },
  CSV: {
    data: '',
    tips: '',
    progress: 0,
    status: API_REQUEST_STATUS.IDLE,
  },
  isPaid: ENROLLMENT_RESULT_IS_PAID_ALL_VALUE,
  selectedCategory: ENROLLMENT_RESULT_CATEGORIES[0].value,
  searchInputText: '',
  verifyAccount: '',
  verifyPassword: '',
  verifyStatus: false,
  verificationModalData: {},
  selectedPaidByOrderId: null,
};

export const { reducer, actions } = createSlice({
  name: 'enrollmentResult',
  initialState,
  reducers: {
    setCsvData: (state, action) => {
      state.csvData = action.payload;
    },
    updatePlayersPageNumber: (state: any, { payload }) => {
      state.playersData.pageNumber = payload;
    },
    updatePlayersPageSize: (state: any, { payload }) => {
      state.playersData.pageSize = payload;
    },
    updateTeamsPageNumber: (state: any, { payload }) => {
      state.teamsData.pageNumber = payload;
    },
    setVerifyAccount: (state, action) => {
      state.verifyAccount = action.payload;
    },
    setVerifyPassword: (state, action) => {
      state.verifyPassword = action.payload;
    },
    setVerifyStatus: (state, action) => {
      state.verifyStatus = action.payload;
    },
    setVerificationModalData: (state, action) => {
      state.verificationModalData = action.payload;
    },
    updateTeamsPageSize: (state: any, { payload }) => {
      state.teamsData.pageSize = payload;
    },
    updateSelectedCategory: (state: any, { payload }) => {
      state.selectedCategory = payload;
    },
    updateSearchInputText: (state: any, { payload }) => {
      state.searchInputText = payload;
    },
    updateIsPaid: (state: any, { payload }) => {
      state.isPaid = payload;
    },
    updateSelectedPaidByOrderId: (state: any, { payload }) => {
      state.selectedPaidByOrderId = payload;
    },
    resultDataReset: (state) => {
      state.enrollmentTitle = '';
      state.playersData = {
        items: [],
        pageSize: 10,
        pageNumber: 0,
        total: 0,
        end: true,
      };
      state.teamsData = {
        items: [],
        pageSize: 10,
        pageNumber: 0,
        total: 0,
        end: true,
      };
      state.categories = [];
      state.summaryTotal = 0;
      state.csvData = '';
      state.notTeamCSV = {
        data: '',
        status: API_REQUEST_STATUS.IDLE,
      };
      state.CSV = {
        data: '',
        tips: '',
        progress: 0,
        status: API_REQUEST_STATUS.IDLE,
      };
      state.isPaid = ENROLLMENT_RESULT_IS_PAID_ALL_VALUE;
      state.selectedCategory = ENROLLMENT_RESULT_CATEGORIES[0].value;
      state.verifyAccount = '';
      state.verifyPassword = '';
      state.verifyStatus = false;
      state.verificationModalData = {};
      state.selectedPaidByOrderId = null;
    },
  },
  extraReducers: {
    [loadEnrollmentPlayersResult.fulfilled.type]: (state, { payload }) => {
      const { teams } = payload || {};
      state.playersData.items = teams;
    },
    [loadEnrollmentTeamsResult.fulfilled.type]: (state, { payload }) => {
      const { teams } = payload || {};
      state.teamsData.items = teams;
    },
    [loadEnrollmentResultSummary.fulfilled.type]: (state, { payload }) => {
      const { categories, total } = payload || {};
      state.categories = categories;
      state.summaryTotal = total || 0;
      state.playersData.total = total || 0;
      state.teamsData.total = total || 0;
    },
    [updateCSVLoadingProgress.fulfilled.type]: (state, { payload }) => {
      state.CSV.progress = payload;
    },
    [updateCSVLoadingTips.fulfilled.type]: (state, { payload }) => {
      state.CSV.tips = payload;
    },
    [exportEnrollmentResultForm.fulfilled.type]: (state, { payload }) => {
      state.CSV.status = API_REQUEST_STATUS.FULFILLED;
      state.CSV.data = payload || '';
    },
    [exportEnrollmentResultForm.pending.type]: (state) => {
      state.CSV.status = API_REQUEST_STATUS.PENDING;
    },
    [exportEnrollmentResultForm.rejected.type]: (state) => {
      state.CSV.status = API_REQUEST_STATUS.REJECTED;
    },
    [exportEnrollmentNotTeamResultForm.pending.type]: (state) => {
      state.notTeamCSV.status = API_REQUEST_STATUS.PENDING;
    },
    [exportEnrollmentNotTeamResultForm.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.notTeamCSV.status = API_REQUEST_STATUS.FULFILLED;
      state.notTeamCSV.data = payload || {};
    },
    [exportEnrollmentNotTeamResultForm.rejected.type]: (state) => {
      state.notTeamCSV.status = API_REQUEST_STATUS.REJECTED;
    },
    [VerifyPassword.fulfilled.type]: (state) => {
      state.verifyStatus = true;
    },
    [loadEnrollmentDetailsById.fulfilled.type]: (state, { payload }) => {
      const { title } = payload || {};

      state.enrollmentTitle = title;
    },
    [removeEnrollmentResultItem.fulfilled.type]: (state) => {
      state.selectedPaidByOrderId = null;
    },
  },
});

export default reducer;
