import { createAsyncThunk } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import {
  errorMessage,
  successMessage,
} from '../../../../../../../common/utils/feedback';
import {
  getEnrollmentPlayersResultById,
  getEnrollmentTeamsResultById,
  getEnrollmentResultFormById,
  getEnrollmentResultSummaryById,
  getEnrollmentNotTeamResultById,
  delEnrollmentResultItemById,
  postVerifyUserPassword,
  getEnrollmentDetail,
  putEnrollmentResultOrderStatus,
  getUnclassifiedPlayers,
  postCreateTeam,
} from './api-request';
import {
  getEnrollmentPlayersPageNumber,
  getEnrollmentPlayersPageSize,
  getEnrollmentResultIsPaid,
  getEnrollmentTeamsPageNumber,
  getEnrollmentTeamsPageSize,
  getIsMultiplePlayer,
  getSelectedPaidByOrderId,
  getVerificationModalData,
  getVerifyAccount,
  getVerifyPassword,
  getEnrollmentResultSelectedCategory,
  getEnrollmentResultSearchText,
} from './selector';
import { getMerchantName } from '../../../../../../../redux/app-v2/merchant/selectors';

export const loadEnrollmentPlayersResult = createAsyncThunk(
  'enrollmentResult/loadEnrollmentPlayersResult',
  async (id: string, { getState }) => {
    try {
      const state = getState();
      const offset: number =
        getEnrollmentPlayersPageNumber(state) *
        getEnrollmentPlayersPageSize(state);
      const limit: number = getEnrollmentPlayersPageSize(state);
      const isPaid: boolean = getEnrollmentResultIsPaid(state);
      const searchInputText = getEnrollmentResultSearchText(state);
      const res: any = await getEnrollmentPlayersResultById({
        id,
        offset,
        limit,
        isPaid,
        text: searchInputText,
      });

      return res.data;
    } catch (e) {
      message.error('加载单人报名详情数据失败');

      throw e;
    }
  }
);

export const loadEnrollmentTeamsResult = createAsyncThunk(
  'enrollmentResult/loadEnrollmentTeamsResult',
  async (id: string, { getState }) => {
    try {
      const state = getState();
      const offset: number =
        getEnrollmentTeamsPageNumber(state) * getEnrollmentTeamsPageSize(state);
      const limit: number = getEnrollmentTeamsPageSize(state);
      const isPaid: boolean = getEnrollmentResultIsPaid(state);
      const searchInputText = getEnrollmentResultSearchText(state);
      const res: any = await getEnrollmentTeamsResultById({
        id,
        offset,
        limit,
        isPaid,
        text: searchInputText,
      });

      return res.data;
    } catch (e) {
      errorMessage('加载团体报名详情数据失败');

      throw e;
    }
  }
);

export const loadEnrollmentResultSummary = createAsyncThunk(
  'enrollmentResult/loadEnrollmentResultSummary',
  async (id: string, { getState }) => {
    try {
      const state: any = getState();
      const multiPlayer = getIsMultiplePlayer(state);
      const res: any = await getEnrollmentResultSummaryById({
        id,
        multiPlayer,
      });

      return res.data;
    } catch (e) {
      message.error('加载报名详情数据统计失败');

      throw e;
    }
  }
);

export const updateCSVLoadingProgress = createAsyncThunk(
  'enrollmentResult/updateCSVLoadingProgress',
  async (progress: number) => {
    return progress;
  }
);

export const updateCSVLoadingTips = createAsyncThunk(
  'enrollmentResult/updateCSVLoadingTips',
  async (tips: string) => {
    return tips;
  }
);

export const exportEnrollmentResultForm = createAsyncThunk(
  'enrollmentResult/exportEnrollmentResultForm',
  async (id: string, { getState }) => {
    try {
      const state: any = getState();
      const multiPlayer = getIsMultiplePlayer(state);
      const result: any = await getEnrollmentResultFormById({
        id,
        multiPlayer,
      });

      return result;
    } catch (e) {
      message.error('导出报名详情数据失败');

      throw e;
    }
  }
);

export const exportEnrollmentNotTeamResultForm = createAsyncThunk(
  'enrollmentResult/exportEnrollmentNotTeamResultForm',
  async (id: string) => {
    try {
      const result: any = await getEnrollmentNotTeamResultById(id);

      return result;
    } catch (e) {
      message.error('导出报名详情数据失败');

      throw e;
    }
  }
);

export const removeEnrollmentResultItem = createAsyncThunk(
  'enrollmentResult/removeEnrollmentResultItem',
  async (
    payload: {
      verificationModalData: any;
      selectedPaidByOrderId: string;
      setDataFunc?: () => void;
    },
    { dispatch, getState }
  ) => {
    try {
      const state = getState();
      const selectedCategory = getEnrollmentResultSelectedCategory(state);
      const { verificationModalData, selectedPaidByOrderId, setDataFunc } =
        payload;
      const { id, enrollmentId, formId } = verificationModalData || {};

      if (selectedPaidByOrderId && selectedPaidByOrderId !== '') {
        const cancelOrderStatusResult = await putEnrollmentResultOrderStatus(
          selectedPaidByOrderId,
          {
            status: 'cancelled',
          }
        );

        const { code, description } = cancelOrderStatusResult || {};

        if (code !== 0 && description) {
          const { description } = cancelOrderStatusResult;

          throw Error(description);
        }
      }

      const res: any = await delEnrollmentResultItemById({
        enrollmentId,
        formId,
        id,
      });
      const { code, description } = res || {};

      if (code !== 0 && description) {
        const { description } = res;

        throw Error(description);
      }

      if (selectedCategory === 'players') {
        dispatch(loadEnrollmentPlayersResult(enrollmentId));
      } else {
        dispatch(loadEnrollmentTeamsResult(enrollmentId));
      }

      successMessage('成功取消分组');
      setDataFunc && setDataFunc();

      return res;
    } catch (error) {
      if (typeof error === 'string') {
        errorMessage(error);
      }

      const { message }: any = error || {};

      if (message) {
        errorMessage(message);
      }

      throw error;
    }
  }
);

export const VerifyPassword = createAsyncThunk(
  'enrollmentResult/verifyPassword',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as any;
      const merchantName = getMerchantName(state);
      const verifyAccount = getVerifyAccount(state);
      const verifyPassword = getVerifyPassword(state);
      const verificationModalData = getVerificationModalData(state);
      const selectedPaidByOrderId = getSelectedPaidByOrderId(state);

      if (
        !verifyAccount ||
        verifyAccount === '' ||
        !verifyPassword ||
        verifyPassword === ''
      ) {
        throw new Error('account error');
      }

      await postVerifyUserPassword(merchantName, {
        accountName: verifyAccount,
        password: verifyPassword,
      });

      message.success('验证成功');
      dispatch(
        removeEnrollmentResultItem({
          verificationModalData,
          selectedPaidByOrderId,
        })
      );
    } catch (e) {
      notification.error({
        message: '验证失败',
        description: '账号或者密码错误',
      });

      throw e;
    }
  }
);

export const loadEnrollmentDetailsById: any = createAsyncThunk(
  'enrollmentDetails/loadEnrollmentDetailsById',
  async (id: string) => {
    try {
      const res: any = await getEnrollmentDetail({ id });

      return res.data;
    } catch (e) {
      message.error('加载报名详情失败');

      throw e;
    }
  }
);

export const loadUnclassifiedPlayers: any = createAsyncThunk(
  'enrollmentResult/loadUnclassifiedPlayers',
  async (id: string) => {
    try {
      const res: any = await getUnclassifiedPlayers({ id });

      return res.data;
    } catch (e) {
      message.error('获取未分组人员失败');

      throw e;
    }
  }
);

export const createTeamById: any = createAsyncThunk(
  'enrollmentResult/createTeamById',
  async (
    { category, merchantName, id, players }: any,
    { dispatch, getState }
  ) => {
    try {
      const payload: {
        id: string;
        data: any;
      } = {
        id,
        data: {
          merchantName,
          category: {
            name: category.categoryName,
            optionName: category.optionName,
          },
          formPlayerIds: players,
        },
      };
      const selectedCategory = getEnrollmentResultSelectedCategory(getState());
      const res: any = await postCreateTeam(payload);

      if (res.code === 0 && res.data) {
        const team = {
          ...res.data,
          ...category,
          clubName: '自定义分组',
          formPlayers: category.formPlayers.filter(({ id }: any) =>
            res.data.formPlayerIds.includes(id)
          ),
        };

        if (selectedCategory === 'players') {
          dispatch(loadEnrollmentPlayersResult(id));
        } else {
          dispatch(loadEnrollmentTeamsResult(id));
        }

        return team;
      } else {
        const message = res.description || res.message || '创建分组人员失败';
        message.error(message);
        throw new Error(message);
      }
    } catch (e) {
      message.error('创建分组人员失败');

      throw e;
    }
  }
);
