import _isEqual from 'lodash/isEqual';
import { createSlice } from '@reduxjs/toolkit';
import {
  loadGameById,
  postCompetitorScore,
  setAndLoadSelectedCompetition,
} from './thunks';
import { CompetitionDetail } from './api-request';

type loadGameDetailRequestTypes = {
  data: any;
  selectedCompetitionId?: string;
  selectedCompetitionData?: CompetitionDetail,
  ableToScoring?: boolean;
};

export type SegmentTypes =
  | 'rest'
  | 'preCompetitionPractice'
  | 'preCompetitionAdjustment'
  | 'competition'
  | 'customization';

export const allSegments: SegmentTypes[] = [
  'preCompetitionPractice',
  'preCompetitionAdjustment',
  'rest',
  'competition',
  'customization',
];

export const getRoundName = (segmentType: SegmentTypes) => {
  let roundName = '';
  switch (segmentType) {
    case 'rest':
      roundName = '赛间休息';
      break;
    case 'preCompetitionPractice':
      roundName = '赛前练习';
      break;
    case 'preCompetitionAdjustment':
      roundName = '靶位调整';
      break;
    case 'competition':
      roundName = '正式比赛';
      break;
    case 'customization':
      roundName = '其他事件';
      break;
  }
  return roundName;
};


export type Segment = {
  segmentType: SegmentTypes;
  displayName: string;
  startTime: string;
  endTime: string;
  targets?: Target[];
};

export type Target = {
  targetNum: string;
  targetNumChildScope?: string[];
  archeryTournamentRoundSettings?: ArcheryTournamentRoundSettings;
};

export type ArcheryTournamentRoundSettings = {
  roundNumber: number;
  isQualifying: boolean;
  competitionHalf: string;
  categoryName: string;
  optionName: string;
  roundName?: string;
  teamPlayerCount: number;
};
const initialState: {
  loadGameDetailRequest: loadGameDetailRequestTypes;

} = {
  loadGameDetailRequest: {
    data: null,
    ableToScoring: true,
  },
};

export const { reducer, actions } = createSlice({
  name: 'association/tournamentScoring',
  initialState,
  reducers: {
    setSelectedCompetitionId: (state, action) => {
      state.loadGameDetailRequest.selectedCompetitionId = action.payload;
      state.loadGameDetailRequest.selectedCompetitionData = undefined;
    },
    setSelectedCompetitionData: (state, action) => {
      state.loadGameDetailRequest.selectedCompetitionData = action.payload;
    },
    setAbleToScoring: (state, action) => {
      state.loadGameDetailRequest.ableToScoring = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadGameById.pending, (state) => {
      state.loadGameDetailRequest.data = null;
    });
    builder.addCase(loadGameById.fulfilled, (state, action) => {
      state.loadGameDetailRequest.data = action.payload;
    });
    builder.addCase(setAndLoadSelectedCompetition.fulfilled, (state, action) => {
      state.loadGameDetailRequest.selectedCompetitionData = action.payload;
    });
    builder.addCase(postCompetitorScore.fulfilled, (state, action) => {
      state.loadGameDetailRequest.ableToScoring = false;
    });
  }
});

export default reducer;
