import {
  get,
  deleteRequest,
  post,
  put,
} from '../../../../../../../service/api';

export const getEnrollmentPlayersResultById = async (payload: {
  id: string;
  offset: number;
  limit: number;
  isPaid: boolean;
  text: string;
}) => {
  try {
    const { id, offset, limit, isPaid, text } = payload;
    const params = { offset, limit, isPaid, search: text };
    const result: any = await get(
      `/association-enrolls/${id}/players`,
      params,
      {}
    );

    return result;
  } catch (e) {
    throw new Error('get enrollment players result by id failed');
  }
};

export const getEnrollmentTeamsResultById = async (payload: {
  id: string;
  offset: number;
  limit: number;
  isPaid: boolean;
  text: string;
}) => {
  try {
    const { id, offset, limit, text } = payload;
    const params = { offset, limit, search: text };
    const result: any = await get(
      `/association-enrolls/${id}/teams`,
      params,
      {}
    );

    return result;
  } catch (e) {
    throw new Error('get enrollment teams result by id failed');
  }
};

export const putEnrollmentResultOrderStatus = async (
  orderId: string,
  payload: {
    status: string;
  }
) => {
  try {
    const result: any = await put(
      `/enrollment-orders/${orderId}/status`,
      payload,
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const delEnrollmentResultItemById = async (payload: {
  id: string;
  enrollmentId: string;
  formId: string;
}) => {
  try {
    const { id, enrollmentId, formId } = payload;
    const result: any = await deleteRequest(
      `/association-enrolls/${enrollmentId}${formId ? `/forms/${formId}` : ''}/teams/${id}`,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getEnrollmentResultFormById = async (payload: {
  id: string;
  multiPlayer: boolean;
}) => {
  try {
    const { id, multiPlayer } = payload;
    const params = { multiPlayer };
    const result: any = await get(
      `/association-enrolls/${id}/export`,
      params,
      {}
    );

    return result;
  } catch (e) {
    throw new Error('export enrollment result form by id failed');
  }
};

export const getEnrollmentNotTeamResultById = async (id: string) => {
  try {
    const result: any = await get(
      `/association-enrolls/${id}/players/export`,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw new Error('get enrollment not team result by id failed');
  }
};

export const getEnrollmentResultSummaryById = async (payload: {
  id: string;
  multiPlayer: boolean;
}) => {
  try {
    const { id, multiPlayer } = payload;
    const params = { multiPlayer };
    const result: any = await get(
      `/association-enrolls/${id}/summary`,
      params,
      {}
    );

    return result;
  } catch (e) {
    throw new Error('get enrollment result summary by id failed');
  }
};

export const postVerifyUserPassword = async (
  merchantName: string,
  payload: {
    accountName: string;
    password: string;
  }
) => {
  try {
    const res = await post(
      `/merchants/${merchantName}/employees.verify`,
      payload
    );
    return res;
  } catch (e) {
    throw new Error('post verify user password failed');
  }
};

export const getEnrollmentDetail = async (payload: { id: string }) => {
  try {
    const { id } = payload;
    const result: any = await get(`/association-enrolls/${id}`, {}, {});

    return result;
  } catch (e) {
    throw new Error('get enrollment details by id failed');
  }
};

export const getUnclassifiedPlayers = async (payload: { id: string }) => {
  try {
    const { id } = payload;
    const result: any = await get(
      `/association-enrolls/${id}/players/unclassified`,
      {},
      {}
    );

    return result;
  } catch (e) {
    throw new Error('get unclassified players by id failed');
  }
};

export const postCreateTeam = async (payload: { id: string; data: any }) => {
  try {
    const result: any = await post(
      `/association-enrolls/${payload.id}/teams`,
      payload.data
    );
    return result;
  } catch (e) {
    throw new Error('create tream failed');
  }
};
