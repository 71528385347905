import {
  get,
  post,
  put,
  deleteRequest,
} from '../../../../../../../service/api';
export const getGameDetail = async (merchantName: string, gameId: string) => {
  try {
    const result: any = await get(
      `/activities/games/${gameId}`,
      {}
    );
    if (result.code !== 0) {
      throw Error(result.message);
    } else {
      return result;
    }
  } catch (error) {
    throw error;
  }
};

export const getTournamentDetail = async (id: string) => {
  try {
    const result: any = await get(
      `/activities/tournaments/${id}`,
      {}
    );

    if (result.code !== 0) {
      throw Error(result.message);
    } else {
      return result.data as TournamentDetail;
    }
  } catch (error) {
    throw error;
  }
}

export interface TournamentDetail {
  id: string;
  demandIds: string[];
  type: string;
  status: string;
  bow: string;
  target: string;
  distance: string;
  environment: string;
  qualifyingArrows: null;
  ownerMerchantName: string;
  consumerIds: any[];
  createdBy: string;
  arrows: string;
  arrowsPerSet: string;
  countType: string;
  proMode: boolean;
  participantCount: number;
  createdTime: Date;
  roomNum: string;
  gameId: string;
  categoryName: string;
  optionName: string;
  teamPlayerCount: number;
  participants: Participant[];
  splitFinalRound: boolean;
  eventSchedule: string;
  rounds: Round[];
  process: string;
  ownerMerchantDisplayName: string;
}

export interface Participant {
  teamId: string;
  players: ParticipantPlayer[];
}

export interface ParticipantPlayer {
  merchantName: string;
  formPlayerId: string;
  consumerId: string;
  playerId: string;
}


export interface Round {
  id: string;
  roundNumber: number;
  bracket?: Bracket[];
  qualifyingRound: boolean;
  tournamentId: string;
  isOnGoing: boolean;
  roundName: string;
}

export interface Bracket {
  id: string;
  consumers: Consumer[];
  competitionId?: string;
  winnerConsumerId?: string;
  isBye?: boolean;
  ordering?: number;
}

export interface Consumer {
  _id: string;
  consumerId: string;
  players: ConsumerPlayer[];
  id: string;
  targetNum?: string;
}

export interface ConsumerPlayer {
  merchantDisplayName?: string;
  name?: string;
  phone?: string;
  gender?: string;
  profileImageUrl?: null | string;
  availableMerchants?: string[];
  _id: string;
  teamId: string;
  consumerId: string;
  merchantName: string;
  playerId: string;
  formPlayerId: string;
}


export const getCompetitionDetail = async (id: string) => {
  try {
    const result: any = await get(
      `/activities/archery/competitions/${id}`,
      {}
    );

    if (result.code !== 0) {
      throw new Error(result.description);
    } else {
      return result.data as CompetitionDetail;
    }

  } catch (error) {
    throw error;
  }
}


export interface CompetitionDetail {
  id: string;
  type: 'knockOut' | 'qualifying';
  roomNum: null;
  status: string;
  createTime: Date;
  bow: string;
  target: string;
  distance: string;
  environment: string;
  arrows: string;
  arrowsPerSet: string;
  ownerId: string;
  ownerMerchantName: string;
  countType: string;
  proMode: boolean;
  resultShared: boolean;
  players: DataPlayer[];
  pin: string;
  tournamentId: string;
  tournamentRoundId: string;
  demandIds: string[];
  winnerConsumerId: null;
  gameId: string;
  categoryName: string;
  optionName: string;
  teamPlayerCount: number;
  goldenArrowSet: boolean;
  isDistanceToTargetCenterSet: boolean;
  knockoutSetConsumerId: string;
  originalOrderingConsumerIds: string[];
  displayStatus: string;
}

export interface DataPlayer {
  consumerId: string;
  targetNum: string;
  players: PlayerPlayer[];
  scoreSum: number;
  scoreWithoutGoldenArrowSum: number;
  scoreXCount: number;
  scoreTenCount: number;
  knockOutPointSum: number;
  scores: Score[];
  ranking: number;
  firstRound: string;
  secondRound: string;
  process: string;
  confirmedGoldenArrowScore?: boolean;
  confirmedDistanceToTargetCenterScore?: boolean;
}

export interface PlayerPlayer {
  teamId: string;
  consumerId: string;
  merchantName: string;
  playerId: string;
  formPlayerId: string;
  name: string;
  phone: string;
  gender: Gender;
  profileImageUrl: null | string;
  availableMerchants: string[];
  merchantDisplayName: string;
}

export enum Gender {
  Female = "female",
  Male = "male",
}

export interface Score {
  id: string;
  consumerId: string;
  values: number[];
  valuesLimit: number;
  xCount: number;
  tenCount: number;
  sum: number;
  recordedTime: Date;
}

export const postScore = async (teamId: string, data: any) => {
  try {
    const result: any = await post(
      `/activities/game/archery/teams/${teamId}/scores`,
      data
    );

    if (result.code !== 0) {
      throw new Error(result.description);
    } else {
      return result;
    }
  } catch (error) {
    throw error;
  }
}

export const updateScore = async (teamId: string, id: string, data: any) => {
  try {
    const result: any = await put(
      `/activities/game/archery/teams/${teamId}/scores/${id}`,
      data
    );

    if (result.code !== 0) {
      throw new Error(result.description);
    } else {
      return result;
    }
  } catch (error) {
    throw error;
  }
}

export const deleteScore = async (teamId: string, id: string) => {
  try {
    const result: any = await deleteRequest(
      `/activities/game/archery/teams/${teamId}/scores/${id}`,
      {}
    );

    if (result.code !== 0) {
      throw new Error(result.description);
    } else {
      return result;
    }
  } catch (error) {
    throw error;
  }
}

export const judgeWinner = async (competitionId: string, winnerConsumerId: string) => {
  try {
    const result: any = await put(
      `/activities/archery/competitions/${competitionId}`,
      { winnerConsumerId, status: 'ended' }
    );

    if (result.code !== 0) {
      throw new Error(result.description);
    } else {
      return result;
    }
  } catch (error) {
    throw error;
  }
}
