import { combineReducers } from '@reduxjs/toolkit';
import players from '../containers/players/redux';
import histories from '../containers/histories/redux';
import tournaments from '../containers/tournaments/containers/TournamentList/redux';
import tournamentsScoring from '../containers/tournaments/containers/TournamentScoring/redux';

const association = combineReducers({
  players,
  histories,
  tournaments,
  tournamentsScoring,
});

export default association;
