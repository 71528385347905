import _get from 'lodash/get';
import _uniqWith from 'lodash/uniqWith';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _differenceWith from 'lodash/differenceWith';
import _isEqual from 'lodash/isEqual';
import _flattenDeep from 'lodash/flattenDeep';
import { RootState } from '../../../../../../../common/utils/types';
import _omit from 'lodash/omit';

export interface GameDetail {
  id: string;
  merchantName: string;
  title: string;
  enrollmentId: string;
  scoreRecordingType: string;
  scoreVerificationMethod: string;
  demandIds: string[];
  targetNums: string[];
  segments: Segment[];
  status: string;
  enrolledCount: number;
  multiPlayerTeamCount: number;
  address: string;
  modifiedTime: Date;
  createdTime: Date;
  enrollmentSummary: EnrollmentSummary[];
  eventSchedule: string;
}

export interface EnrollmentSummary {
  id: string;
  categoryName: string;
  optionName: string;
  teamPlayerCount: number;
  splitFinalRound: boolean;
  participants: Participant[];
  archerySettings: ArcherySettings;
}

export interface ArcherySettings {
  _id: string;
  bow: string;
  target: string;
  distance: string;
  environment: string;
  arrows: string;
  arrowsPerSet: string;
}


export interface Participant {
  formPlayerIds: string[];
  _id: string;
  teamId: string;
}

export interface Segment {
  id: string;
  segmentType: SegmentType;
  displayName: string;
  startTime: Date;
  endTime: Date;
  status?: "preparation" | "started" | "ended" | undefined;
  archeryTournamentRoundSettingsList: ArcheryTournamentRoundSettings[];
  targets: Target[];
}

export interface ArcheryTournamentRoundSettings {
  id: string;
  roundNumber: number;
  isQualifying: boolean;
  roundName: string;
  categoryName: string;
  optionName: string;
  teamPlayerCount: number;
  tournamentId: string;
}


export enum SegmentType {
  Competition = "competition",
}

export interface Target {
  id: string;
  targetNum: string;
  targetNumChildScope: TargetNumChildScope[];
  archeryTournamentRoundSettings: ArcheryTournamentRoundSettings | null;
}

export enum TargetNumChildScope {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
}


export const getGameDetailData = (state: RootState) =>
  state.association.tournamentsScoring.loadGameDetailRequest?.data as GameDetail;

export const getSelectedCompetitionId = (state: RootState) =>
  state.association.tournamentsScoring.loadGameDetailRequest?.selectedCompetitionId;

export const getSelectedCompetitionData = (state: RootState) =>
  state.association.tournamentsScoring.loadGameDetailRequest?.selectedCompetitionData;

export const getAbleToScoring = (state: RootState) =>
  state.association.tournamentsScoring.loadGameDetailRequest?.ableToScoring;
