import _isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Modal, Popover, Row, Tooltip, notification } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { getFormatRequestDataTime } from '../../../../../../../../../common/utils/time-lib';
import {
  Segment,
  Target,
  actions as createGameRequestActions,
} from '../../../redux';
import SetProjectTargetPopover from './SetProjectTargetPopover';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsCreateTargetsSettingModal,
  getCreateGameRequestSelectedTargets,
  getGameDetailSelectedTargets,
  getCreateGameRequestSegments,
  getGameDetailSegments,
  getGameLegendTournaments,
  getGameDetailSelectedSetTargetsInfo,
  getCreateGameRequestSelectedSetTargetsInfo,
  getIsTargetsSettingEnabled,
  getTargetNums,
  getIsGameNotStarted,
} from '../../../redux/selectors';
import { generateColor, useEvents } from '../../../utils';
import SegmentSelectionPopover, {
  SegmentEditingView,
} from './SegmentSelectionPopover';
import { actions as gameRequestActions } from '../../../redux';
import './index.less';
import { TARGET_LEGEND_COMPETITION_HALF } from '../../../utils/constants';
import moment from 'moment';
import { useEvent, useInterval } from 'react-use';
import SegmentCellHeader from './SegmentHeader';
import SegmentNormalCell from './SegmentNormalCell';
import { DragPreviewImage, useDragDropManager, useDrop } from 'react-dnd';
import {
  errorMessage,
  successMessage,
} from '../../../../../../../../../common/utils/feedback';

const selectedTargetsTitle = '已选';
const emptyTargetsTitle = '空';

interface SegmentCellProps {
  targetNums: string[];
  segment: Segment & { arranges: boolean[] };
  segmentIndex: number;
  onInsertSegment?: (segment: Segment, position: 'top' | 'bottom') => void;
  onUpdateSegment?: (segment: Segment) => void;
  onDeleteSegment?: (segment: Segment) => void;
}
const SegmentCell: React.FC<SegmentCellProps> = ({
  targetNums,
  segment,
  segmentIndex,
  onInsertSegment,
  onUpdateSegment,
  onDeleteSegment,
}) => {
  const segmentType = segment.segmentType;
  switch (segmentType) {
    case 'competition':
      return (
        <SegmentGameCell
          targetNums={targetNums}
          segment={segment}
          segmentIndex={segmentIndex}
          onInsertSegment={onInsertSegment}
          onUpdateSegment={onUpdateSegment}
          onDeleteSegment={onDeleteSegment}
        />
      );
    default:
      return (
        <SegmentNormalCell
          segment={segment}
          segmentIndex={segmentIndex}
          targetNums={targetNums}
          onInsertSegment={onInsertSegment}
          onUpdateSegment={onUpdateSegment}
          onDeleteSegment={onDeleteSegment}
        />
      );
  }
};

export default SegmentCell;

interface SegmentGameCellProps {
  targetNums: string[];
  segment: Segment & { arranges: boolean[] };
  segmentIndex: number;
  onInsertSegment?: (segment: Segment, position: 'top' | 'bottom') => void;
  onUpdateSegment?: (segment: Segment) => void;
  onDeleteSegment?: (segment: Segment) => void;
}
const SegmentGameCell: React.FC<SegmentGameCellProps> = ({
  targetNums,
  segment,
  segmentIndex,
  onInsertSegment,
  onUpdateSegment,
  onDeleteSegment,
}) => {
  const dispatch = useDispatch();
  const isCreateTargetsSettingModal = useSelector(
    getIsCreateTargetsSettingModal
  );
  const isTargetsSettingEnabled = useSelector(getIsTargetsSettingEnabled);
  const createGameRequestSelectedTargets = useSelector(
    getCreateGameRequestSelectedTargets
  );
  const gameDetailSelectedTargets = useSelector(getGameDetailSelectedTargets);
  const selectedTargetsObj = isCreateTargetsSettingModal
    ? createGameRequestSelectedTargets
    : gameDetailSelectedTargets;

  const selectedTargets = selectedTargetsObj
    ? selectedTargetsObj[segmentIndex] || []
    : [];

  const legends: any[] = useSelector(getGameLegendTournaments);

  const gameDetailSelectedSetTargetsInfo = useSelector(
    getGameDetailSelectedSetTargetsInfo
  );
  const createGameRequestSelectedSetTargetsInfo = useSelector(
    getCreateGameRequestSelectedSetTargetsInfo
  );
  const selectedSetTargetsInfo = isCreateTargetsSettingModal
    ? createGameRequestSelectedSetTargetsInfo
    : gameDetailSelectedSetTargetsInfo;
  let selectedTargetsInfoTitle: any = null;

  if (selectedSetTargetsInfo) {
    const {
      archeryTournamentRoundSettings:
        selectedTargetsInfoArcheryTournamentRoundSettings,
      targetNumChildScope: selectedTargetsTargetNumChildScope,
    }: any = selectedSetTargetsInfo || {};
    const {
      categoryName: selectedTargetsInfoCategoryName,
      optionName: selectedTargetsOptionName,
      competitionHalf: selectedTargetsCompetitionHalf,
      roundName: selectedTargetsRoundName,
    } = selectedTargetsInfoArcheryTournamentRoundSettings || {};
    const selectedTargetsInfoCompetitionHalfString =
      !selectedTargetsCompetitionHalf
        ? ''
        : selectedTargetsCompetitionHalf ===
          TARGET_LEGEND_COMPETITION_HALF.FIRSTHALF
        ? '-上'
        : '-下';
    let selectedTargetsInfoTargetNumberChildScopeString = '';

    selectedTargetsTargetNumChildScope?.forEach((item: string) => {
      selectedTargetsInfoTargetNumberChildScopeString += item;
    });

    selectedTargetsInfoTitle = `${selectedTargetsInfoCategoryName}${selectedTargetsOptionName} · ${selectedTargetsRoundName}${selectedTargetsInfoCompetitionHalfString}(${selectedTargetsInfoTargetNumberChildScopeString}型)`;
  }

  const [dropOverStatus, setDropOverStatus] = useState<boolean[]>([]);
  const dragItem = useDragDropManager().getMonitor().getItem();

  // 将targets按照categoryName和optionName分组
  const groupedTargets = useCallback(() => {
    const segmentTargets = segment.targets || [];
    if (segmentTargets.length === 0) {
      return [];
    }

    const getTargetTitle = (target: Target) => {
      const { targetNumChildScope, archeryTournamentRoundSettings } =
        target || {};
      let targetNumberChildScopeString = '';

      targetNumChildScope?.forEach((item) => {
        targetNumberChildScopeString += item;
      });
      const legend = legends.find(
        (l) =>
          target.archeryTournamentRoundSettings?.categoryName ===
            l.categoryName &&
          target.archeryTournamentRoundSettings?.optionName === l.optionName
      );
      if (selectedTargets.includes(target.targetNum)) {
        return `${selectedTargetsTitle}(${selectedTargets.length}个靶)(再次点击删除)`;
      } else if (archeryTournamentRoundSettings) {
        const { competitionHalf }: { competitionHalf: string } =
          archeryTournamentRoundSettings || {};
        const competitionHalfString = !competitionHalf
          ? ''
          : competitionHalf === TARGET_LEGEND_COMPETITION_HALF.FIRSTHALF
          ? '-上'
          : '-下';

        return `（${legend?.archerySettings?.distance}米）${archeryTournamentRoundSettings?.categoryName}${archeryTournamentRoundSettings?.optionName} · ${archeryTournamentRoundSettings?.roundName}${competitionHalfString}(${targetNumberChildScopeString}型)`;
      } else {
        return emptyTargetsTitle;
      }
    };
    const getTargetColor = (target: Target) => {
      if (
        !selectedTargets.includes(target.targetNum) &&
        !target.archeryTournamentRoundSettings
      ) {
        return 'black';
      } else {
        return 'white';
      }
    };

    const getTargetBackgroundColor = (target: Target) => {
      // 如果是选中item 返回蓝色
      if (selectedTargets.includes(target.targetNum)) {
        return 'blue';
      } else if (target.archeryTournamentRoundSettings) {
        return generateColor(
          legends.findIndex(
            (l) =>
              target.archeryTournamentRoundSettings?.categoryName ===
                l.categoryName &&
              target.archeryTournamentRoundSettings?.optionName === l.optionName
          )
        );
      } else {
        return 'rgba(255,255,255,0.9)';
      }
    };

    let ableToSet: boolean | undefined = dragItem ? false : undefined;
    const startIndex = dropOverStatus.indexOf(true);
    const length = dragItem
      ? Math.ceil(
          dragItem.participants /
            (dragItem.targetNumChildScope.current.length || 1)
        )
      : 0;
    if (dragItem) {
      ableToSet =
        dragItem &&
        segment.arranges.length >= startIndex + length &&
        segment.arranges
          .slice(startIndex, startIndex + length)
          .every((i: any) => i);
    }
    type ReturnItem = {
      index: number;
      length: number;
      title: string;
      color: string;
      backgroundColor: string;
      isSelectedTitle: boolean;
      borderColor: string;
    };
    const groups: ReturnItem[] = segmentTargets.map((item, i) => {
      const currentTitle = getTargetTitle(item);
      const currentColor = getTargetColor(item);
      const currentBackgroundColor = getTargetBackgroundColor(item);
      const borderColor =
        startIndex < 0 || i < startIndex || i > startIndex + length - 1
          ? 'transparent'
          : ableToSet
          ? 'green'
          : 'red';

      return {
        index: parseInt(item.targetNum) - 1,
        length: 1,
        title: currentTitle,
        backgroundColor: currentBackgroundColor,
        color: currentColor,
        isSelectedTitle: selectedTargetsInfoTitle === currentTitle,
        borderColor:
          currentTitle === emptyTargetsTitle ? borderColor : 'transparent',
      };
    });
    const mergedArr: ReturnItem[] = groups.reduce(
      (result: ReturnItem[], current) => {
        const existingItem = result.find(
          (item) =>
            item.title === current.title &&
            item.backgroundColor === current.backgroundColor &&
            item.color === current.color &&
            item.title !== emptyTargetsTitle
        );

        if (existingItem) {
          existingItem.length += current.length;
        } else {
          result.push(current);
        }

        return result;
      },
      []
    );
    return mergedArr;
  }, [
    segment,
    dragItem,
    selectedTargets,
    legends,
    dropOverStatus,
    selectedTargetsInfoTitle,
  ]);

  const onDropEnd = (dragItem: any, item: any) => {
    const ableToSet =
      dragItem &&
      segment.arranges.length >= dragItem.startIndex + dragItem.length &&
      segment.arranges
        .slice(dragItem.startIndex, dragItem.startIndex + dragItem.length)
        .every((i: any) => i);
    if (!ableToSet) {
      errorMessage('靶位数量不正确，无法设置');
      return;
    }

    if (isCreateTargetsSettingModal) {
      dispatch(
        gameRequestActions.createGameRequestSegmentSetTargets({
          segmentIndex,
          startIndex: dragItem.startIndex,
          length: dragItem.length,
          archeryTournamentRoundSettings: item.archeryTournamentRoundSettings,
          targetNumChildScope: item.targetNumChildScope.current,
        })
      );
    } else {
      dispatch(
        gameRequestActions.detailGameSegmentSetTargets({
          segmentIndex,
          startIndex: dragItem.startIndex,
          length: dragItem.length,
          archeryTournamentRoundSettings: item.archeryTournamentRoundSettings,
          targetNumChildScope: item.targetNumChildScope.current,
        })
      );
    }
  };

  return (
    <Row
      gutter={4}
      wrap={false}
      align="middle"
      key={`SegmentGameCell_row_${segmentIndex}}`}
    >
      <SegmentCellHeader
        segment={segment}
        segmentIndex={segmentIndex}
        onInsertSegment={onInsertSegment}
        onUpdateSegment={onUpdateSegment}
      />
      {groupedTargets().map((item, index) => {
        const onClick = (e: any) => {
          if (!isTargetsSettingEnabled) {
            return;
          }
          e.currentTarget.blur();
          if (!segment.targets) {
            return;
          }
          const segmentItem = segment.targets[item.index];
          const { archeryTournamentRoundSettings, targetNumChildScope } =
            segmentItem || {};

          if (
            archeryTournamentRoundSettings &&
            !_isEmpty(archeryTournamentRoundSettings) &&
            targetNumChildScope &&
            targetNumChildScope.length > 0 &&
            selectedTargets.length === 0
          ) {
            isCreateTargetsSettingModal
              ? dispatch(
                  createGameRequestActions.createGameRequestSelectedSetTargetsInfo(
                    { selectedItem: segmentItem, segmentIndex }
                  )
                )
              : dispatch(
                  createGameRequestActions.loadGameDetailRequestSelectedSetTargetsInfo(
                    { selectedItem: segmentItem, segmentIndex }
                  )
                );
          }
        };

        const onDelete = (e: any) => {
          if (isCreateTargetsSettingModal) {
            dispatch(
              createGameRequestActions.selectedCreateSetTargetsDeleted({
                selectedTargets,
                segmentIndex,
              })
            );
          } else {
            dispatch(
              createGameRequestActions.selectedDetailSetTargetsDeleted({
                selectedTargets,
                segmentIndex,
              })
            );
          }
        };

        return (
          <GroupItem
            segment={segment}
            item={item}
            index={item.index}
            segmentIndex={segmentIndex}
            onHoverChange={(isOver) => {
              if (dropOverStatus[item.index] !== isOver) {
                setDropOverStatus((p) => {
                  const n = [...p];
                  n[item.index] = isOver;
                  return n;
                });
              }
            }}
            onDropEnd={onDropEnd}
            onClick={
              item.title !== emptyTargetsTitle &&
              !item.title.includes(selectedTargetsTitle)
                ? onClick
                : item.title.includes(selectedTargetsTitle)
                ? onDelete
                : () => {}
            }
          />
        );
      })}
      {isTargetsSettingEnabled ? (
        <Col
          style={{
            position: 'sticky',
            right: 0,
            zIndex: 1,
            background: 'white',
          }}
        >
          <Button
            disabled={!isTargetsSettingEnabled}
            onClick={(e) => onDeleteSegment && onDeleteSegment(segment)}
            shape="default"
            danger
            ghost
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            onFocus={(e) => {
              e.currentTarget.blur();
            }}
            icon={<CloseOutlined style={{ color: '#ff0000' }} />}
          />
        </Col>
      ) : null}
    </Row>
  );
};
interface GroupItemProps {
  item: any;
  segment: Segment;
  segmentIndex: number;
  index: number;
  onClick: (e: any) => void;
  onHoverChange: (isOver: boolean) => void;
  onDropEnd: (dragItem: any, item: any) => void;
}
const GroupItem: React.FC<GroupItemProps> = ({
  item,
  segment,
  segmentIndex,
  index,
  onClick,
  onHoverChange,
  onDropEnd,
}) => {
  const { backgroundColor, color, borderColor, length, startIndex, title } =
    item;

  const content = (
    <div
      className="TargetSettingTableItem flex flex-middle flex-center"
      style={{
        color,
        backgroundColor,
        overflow: 'hidden',
        ...(borderColor === 'transparent'
          ? {}
          : { border: `4px ${borderColor} solid` }),
      }}
      onClick={onClick}
    >
      <span>{title}</span>
    </div>
  );

  const [{ isOver }, drop] = useDrop(
    {
      accept: 'targetSetting',
      drop: (dropItem: any, monitor) => {
        const dragItem = {
          startIndex: index,
          length: Math.ceil(
            dropItem.participants /
              (dropItem.targetNumChildScope.current.length || 1)
          ),
        };
        onDropEnd(dragItem, dropItem);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    },
    [item, index]
  );
  useEffect(() => {
    onHoverChange(isOver);
  }, [isOver, onHoverChange]);
  return (
    <Col
      key={`SegmentGameCell_${segmentIndex}_${item.index}_${item.length}`}
      id={`SegmentGameCell_${segmentIndex}_${item.index}_${item.length}`}
      ref={drop}
      style={{
        flex: `0 0 ${
          (525 / 6 / Math.min(22, segment.targets?.length || 1)) * length
        }%`,
        // flex: `0 0 ${Math.floor(length / 24) * 100 + (length % 24) / 0.24}%`,
        maxWidth: 'none',
      }}
    >
      {title === emptyTargetsTitle ? (
        content
      ) : (
        <Tooltip
          title={title}
          overlayStyle={{ zIndex: 2001 }}
          destroyTooltipOnHide
        >
          {content}
        </Tooltip>
      )}
    </Col>
  );
};
