/* eslint-disable react-hooks/rules-of-hooks */
import _isNill from 'lodash/isNil';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Card,
  Col,
  ConfigProvider,
  List,
  message,
  Row,
  Skeleton,
  Tabs,
} from 'antd';
import { FireFilled, LoadingOutlined } from '@ant-design/icons';
import { useMount } from 'react-use';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch } from '../../../../../../common/utils/types';
import { getIsUserInitialized } from '../../../../../../redux/app-v2/user/selectors';
import {
  ArcheryTournamentRoundSettings,
  getGameDetailData,
} from './redux/selectors';
import { loadGameById } from './redux/thunks';
import { actions as tournamentListActions } from './redux';
import ContentDetail from '../../../../../../common/components/ContentDetail';

import { errorMessage } from '../../../../../../common/utils/feedback';
import BreadcrumbRouter from '../../../../../../common/components/BreadcrumbRouter';
import ScoringModal from './components/ScoringModal';
import {
  Bracket,
  CompetitionDetail,
  getCompetitionDetail,
  getTournamentDetail,
  Round,
  TournamentDetail,
} from './redux/api-request';
import { isEmpty } from 'lodash';
import Button from './components/button';

const TournamentScoring = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isUserInitialized = useSelector(getIsUserInitialized);
  const gameData = useSelector(getGameDetailData);
  const { id } = useParams();

  useEffect(() => {
    if (_isNill(id)) {
      errorMessage('缺少赛事ID');
      navigate('/association-activities/tournaments');
    } else {
      dispatch(loadGameById(id) as any);
    }
  }, [isUserInitialized, id]);

  return (
    <ConfigProvider autoInsertSpaceInButton>
      <ContentDetail
        title="赛事活动联赛 / 分数录入"
        headerLeft={
          <BreadcrumbRouter
            routes={[
              {
                path: '/association-activities/tournaments',
                breadcrumbName: '赛事活动联赛',
              },
              {
                path: `${id}`,
                breadcrumbName: '分数录入',
              },
            ]}
          />
        }
      >
        {gameData ? (
          <Card title={gameData.title} bordered={false}>
            <Tabs type="card" size="small" centered>
              {gameData.segments?.map((segment, index) => {
                const listToRender =
                  segment.archeryTournamentRoundSettingsList.map(
                    (setting, targetIndex) => {
                      return {
                        title: `${setting.categoryName}${setting.optionName}-${setting.roundName}`,
                        description: `${setting.teamPlayerCount}人`,
                        setting,
                      };
                    }
                  );
                return (
                  <Tabs.TabPane
                    key={index}
                    tab={
                      <Row
                        style={{ width: '100%' }}
                        align="middle"
                        justify="center"
                        gutter={4}
                      >
                        {segment.status == 'started' && (
                          <FireFilled
                            style={{
                              fontSize: '22px',
                              color: 'red',
                              marginRight: 0,
                            }}
                          />
                        )}
                        {segment.status == 'preparation' && (
                          <LoadingOutlined
                            style={{
                              marginRight: 0,
                            }}
                          />
                        )}
                        <Col
                          span={24}
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}
                        >
                          {segment.displayName}
                        </Col>
                      </Row>
                    }
                  >
                    <List
                      itemLayout="horizontal"
                      style={{ maxHeight: '78vh', overflowY: 'auto' }}
                      bordered
                      dataSource={listToRender}
                      renderItem={TournamentCell}
                    />
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          </Card>
        ) : (
          <Skeleton loading />
        )}
        <ScoringModal />
      </ContentDetail>
    </ConfigProvider>
  );
};

export default TournamentScoring;

const TournamentCell: FC<{
  title: string;
  description: string;
  setting: ArcheryTournamentRoundSettings;
}> = (item) => {
  const [tournamentData, setTournamentData] =
    React.useState<TournamentDetail>();
  const refresh = async () =>
    new Promise((resolve, reject) => {
      item.setting.tournamentId &&
        getTournamentDetail(item.setting.tournamentId)
          .then((data) => {
            setTournamentData(data);
            resolve(data);
          })
          .catch((e) => {
            message.error('获取赛事详情失败');
            reject(e);
          });
    });

  useEffect(() => {
    refresh();
  }, [item.setting.tournamentId]);
  return (
    <List.Item
      extra={
        <Row
          justify="end"
          align="middle"
          wrap={false}
          style={{ gap: '10px', width: '70%' }}
        >
          <BracketView
            brackets={
              tournamentData?.rounds?.find(
                (r: Round) => r.roundNumber === item.setting.roundNumber
              )?.bracket
            }
          />
          {tournamentData?.status !== 'ended' && (
            <Button onClick={refresh}>刷新</Button>
          )}
        </Row>
      }
    >
      <List.Item.Meta
        style={{ maxWidth: '30%' }}
        title={item.title}
        description={item.description}
      />
    </List.Item>
  );
};

const BracketView: FC<{ brackets?: Bracket[] }> = ({ brackets }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [competitionDatas, setCompetitionDatas] =
    React.useState<(CompetitionDetail | undefined)[]>();

  useEffect(() => {
    Promise.all(
      brackets?.map((b) => {
        return b.competitionId
          ? getCompetitionDetail(b.competitionId).then((data) => {
              return data;
            })
          : undefined;
      }) ?? []
    ).then((data) => {
      setCompetitionDatas(data);
    });
  }, [brackets]);

  const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

  return brackets?.length ? (
    <Row wrap={true} align="middle" justify="end" style={{ gap: '10px' }}>
      {competitionDatas ? (
        competitionDatas.map((competition, index) => {
          return (
            <>
              {isEmpty(brackets[index].competitionId) ? (
                <Button
                  key={'bracet-button_' + index}
                  type="ghost"
                  disabled
                  style={{
                    height: '100%',
                    padding: '4px',
                  }}
                >
                  <Row align="middle" justify="center">
                    <Avatar.Group>
                      {brackets[index].consumers[0]?.players?.map(
                        (p: any, index: number) => (
                          <Avatar
                            key={'bracet-button_' + index + 'avatar_' + index}
                            size={48}
                            style={{ backgroundColor: ColorList[index] }}
                          >
                            {p.name}
                          </Avatar>
                        )
                      )}
                    </Avatar.Group>
                    <span>VS</span>
                    <Avatar.Group>
                      <Avatar
                        size={48}
                        style={{ backgroundColor: 'lightgray' }}
                      >
                        轮空
                      </Avatar>
                    </Avatar.Group>
                  </Row>
                </Button>
              ) : isEmpty(brackets[index].consumers) ? (
                <Button
                  key={'bracet-button_' + index}
                  type="primary"
                  onClick={() => {
                    dispatch(
                      tournamentListActions.setSelectedCompetitionId(
                        brackets[index].competitionId
                      )
                    );
                  }}
                >
                  排位赛
                </Button>
              ) : (
                <Button
                  key={'bracet-button_' + index}
                  type="ghost"
                  style={{
                    height: '100%',
                    padding: '4px',
                  }}
                  onClick={() => {
                    dispatch(
                      tournamentListActions.setSelectedCompetitionId(
                        brackets[index].competitionId
                      )
                    );
                  }}
                >
                  <Row
                    align="middle"
                    justify="center"
                    style={{ width: '100%' }}
                  >
                    {competition?.players
                      ?.map((c) => {
                        return (
                          <>
                            {c.players.map((p, index: number) => (
                              <Avatar
                                key={
                                  'bracet-button_' + index + 'avatar_' + index
                                }
                                size={48}
                                style={{ backgroundColor: ColorList[index] }}
                                src={p.profileImageUrl}
                              >
                                {p.name}
                              </Avatar>
                            ))}
                            <Avatar size={48}>{`${c.targetNum}号靶`}</Avatar>
                          </>
                        ) as any;
                      })
                      .reduce((prev: any, curr: any) => [
                        <Avatar.Group>{prev}</Avatar.Group>,
                        <span>VS</span>,
                        <Avatar.Group>{curr}</Avatar.Group>,
                      ])}
                  </Row>
                </Button>
              )}
            </>
          );
        })
      ) : (
        <LoadingOutlined />
      )}
    </Row>
  ) : (
    <></>
  );
};
