import _isEmpty from 'lodash/isEmpty';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../common/utils/types';
import {
  deleteScore,
  getCompetitionDetail,
  getGameDetail,
  judgeWinner,
  postScore,
  updateScore,
} from './api-request';
import { getMerchantName } from '../../../../../../../redux/app-v2/merchant/selectors';
import {
  getSelectedCompetitionId,
} from './selectors';
import { actions as scoringActions } from './index';
import { notification } from 'antd';

export const loadGameById = createAsyncThunk(
  'association/tournamentScoring/loadGameById',
  async (gameId: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const result = await getGameDetail(merchantName, gameId);
      const { data }: any = result || {};
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const setAndLoadSelectedCompetition = createAsyncThunk(
  'association/tournamentScoring/setAndLoadSelectedCompetition',
  async (competitionId: string, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const selectedCompetitionId = getSelectedCompetitionId(state);
      if (selectedCompetitionId !== competitionId) {
        dispatch(scoringActions.setSelectedCompetitionId(competitionId));
      }
      const result = await getCompetitionDetail(competitionId);
      return result;
    } catch (error) {
      throw error;
    }
  }
);

const delayAbleToScoring = createAsyncThunk(
  'association/tournamentScoring/delayAbleToScoring',
  async (_, { dispatch }) => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    dispatch(scoringActions.setAbleToScoring(true));
  }
);
export const postCompetitorScore = createAsyncThunk(
  'association/tournamentScoring/postCompetitorScore',
  async (params: any, { getState, dispatch }) => {
    try {
      const { teamId, scores } = params;
      const state = getState() as RootState;
      const selectedCompetitionId = getSelectedCompetitionId(state);
      const result = await postScore(teamId, scores);
      selectedCompetitionId && await dispatch(setAndLoadSelectedCompetition(selectedCompetitionId));
      notification.success({ message: '分数录入成功' });
      dispatch(delayAbleToScoring());
      return result;
    } catch (error: any) {
      console.error('分数录入失败', error);
      notification.error({ message: error.message || "分数录入失败" });
      throw error;
    }
  }
);

export const updateCompetitorScore = createAsyncThunk(
  'association/tournamentScoring/updateCompetitorScore',
  async (params: any, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const { teamId, scoreId, scores } = params;
      const selectedCompetitionId = getSelectedCompetitionId(state);
      const result = await updateScore(teamId, scoreId, scores);
      selectedCompetitionId && await dispatch(setAndLoadSelectedCompetition(selectedCompetitionId));
      notification.success({ message: '分数更新成功' });
      return result;
    } catch (error: any) {
      console.error('分数更新失败', error);
      notification.error({ message: error.message || "分数更新失败" });
      throw error;
    }
  }
);

export const deleteCompetitorScore = createAsyncThunk(
  'association/tournamentScoring/deleteCompetitorScore',
  async (params: any, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const selectedCompetitionId = getSelectedCompetitionId(state);
      const { teamId, scoreId } = params;
      const result = await deleteScore(teamId, scoreId);
      selectedCompetitionId && await dispatch(setAndLoadSelectedCompetition(selectedCompetitionId));

      notification.success({ message: '分数删除成功' });
      return result;
    } catch (error: any) {
      console.error('分数删除失败', error.message);
      notification.error({ message: error.message || "分数删除失败" });
      throw error;
    }
  }
);

export const judgeCompetitionWinner = createAsyncThunk(
  'association/tournamentScoring/judgeCompetitionWinner',
  async (params: any, { dispatch }) => {
    try {
      const { competitionId, winnerConsumerId } = params;
      const result = await judgeWinner(competitionId, winnerConsumerId);
      await dispatch(setAndLoadSelectedCompetition(competitionId));
      notification.success({ message: '设置胜者成功' });
      return result;
    } catch (error: any) {
      console.error('设置胜者失败', error);
      notification.error({ message: error.message || "设置胜者失败" });
      throw error;
    }
  }
);
