import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  List,
  Avatar,
  Col,
  Row,
  Input,
  Divider,
  Layout,
  Statistic,
  Popconfirm,
  Form,
} from 'antd';
import { actions as tournamentsActions } from '../../redux';
import './index.less';
import { useInterval } from 'react-use';
import { isString, parseInt } from 'lodash';
import {
  getAbleToScoring,
  getSelectedCompetitionData,
  getSelectedCompetitionId,
} from '../../redux/selectors';
import {
  DataPlayer,
  getCompetitionDetail,
  Score,
} from '../../redux/api-request';
import {
  deleteCompetitorScore,
  judgeCompetitionWinner,
  postCompetitorScore,
  updateCompetitorScore,
} from '../../redux/thunks';
import { AppDispatch } from '../../../../../../../../redux/store';
import Button from '../button';

type ScoringModalProps = {};
const ScoringModal: React.FC<ScoringModalProps> = () => {
  const dispatch = useDispatch();
  const competitionId = useSelector(getSelectedCompetitionId);
  const competitionData = useSelector(getSelectedCompetitionData);

  const refresh = async () => {
    if (isString(competitionId)) {
      getCompetitionDetail(competitionId).then((res) => {
        dispatch(tournamentsActions.setSelectedCompetitionData(res));
      });
    }
  };

  useEffect(() => {
    refresh();
  }, [competitionId]);

  useInterval(async () => {
    (!competitionData || competitionData?.status === 'started') && refresh();
  }, 2000);

  return (
    <Modal
      centered
      width="90%"
      bodyStyle={{ display: 'flex', flexFlow: 'column', gap: '10px' }}
      closable={false}
      visible={isString(competitionId)}
      footer={null}
      onCancel={() =>
        dispatch(tournamentsActions.setSelectedCompetitionId(undefined))
      }
    >
      <header className="flex flex-middle flex-space-between">
        <Button
          onClick={() => {
            dispatch(tournamentsActions.setSelectedCompetitionId(undefined));
          }}
        >
          取消
        </Button>
        <h1>分数录入</h1> <Button />
      </header>
      <Divider style={{ margin: 0 }} />
      <div style={{ overflow: 'auto', height: '75vh' }}>
        <List
          dataSource={
            competitionData?.players
              ? [...competitionData.players].sort((a, b) => {
                  const numDiff = parseInt(a.targetNum) - parseInt(b.targetNum);
                  if (numDiff !== 0) {
                    return numDiff;
                  }
                  return a.targetNum.localeCompare(b.targetNum);
                })
              : []
          }
          renderItem={(player) => <PlayerScore player={player} />}
          loading={!competitionData}
        />
      </div>
    </Modal>
  );
};

ScoringModal.displayName = 'ScoringModal';

export default ScoringModal;

const valueToArcherScore = (value: string | number) => {
  let score = typeof value === 'string' ? parseInt(value) : value;
  if (isNaN(score)) {
    return '';
  }
  if (score == 0) {
    return 'M';
  }
  if (score == 11) {
    return 'X';
  }
  return score + '';
};

const ScoreRow: React.FC<{
  key?: string;
  score: Score;
  unableToScoring: boolean;
  unableToChangeScore: boolean;
  onUpdate: (updatedScore: Score) => Promise<void>;
}> = ({ key, score, onUpdate, unableToScoring, unableToChangeScore }) => {
  const [values, setValues] = useState<number[]>(score.values);
  const [originalValues] = useState<number[]>(score.values); // Store original values for reset
  const [isUpdated, setIsUpdated] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const handleInputChange = (value: string, index: number) => {
    const updatedValues = [...values];

    // Validate and process input like ScoringPopconfirm
    const normalizedValue = value.toLowerCase();
    if (
      normalizedValue === 'x' ||
      normalizedValue === '' ||
      (!isNaN(parseInt(normalizedValue, 10)) &&
        parseInt(normalizedValue, 10) >= 0 &&
        parseInt(normalizedValue, 10) <= 11)
    ) {
      updatedValues[index] =
        normalizedValue === 'x' ? 11 : parseInt(normalizedValue, 10);
      setValues(updatedValues);
      setIsUpdated(true);
    }
  };

  const handleUpdate = async () => {
    await onUpdate({ ...score, values });
    setIsUpdated(false);
  };

  const handleReset = () => {
    setValues(originalValues); // Reset to original values
    setIsUpdated(false);
  };

  const handleDeleteScore = async () => {
    dispatch(
      deleteCompetitorScore({ teamId: score.consumerId, scoreId: score.id })
    );
  };

  return (
    <Row align="middle" gutter={8} key={'score_row_' + key}>
      {values.map((value, index) => (
        <Input
          key={'score_row_' + key + '_' + index}
          size="middle"
          style={{
            width: '45px',
            textAlign: 'center',
            alignContent: 'center',
            margin: '4px',
            marginRight: '0',
            color: value !== score.values[index] ? 'red' : '',
          }}
          onFocus={(e) => {
            if (unableToChangeScore) {
              e.target.blur();
            }
          }}
          value={valueToArcherScore(value)}
          onChange={(e) => handleInputChange(e.target.value, index)}
        />
      ))}
      <Col>=</Col>
      <Col>
        {isUpdated ? (
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            {values.reduce((sum, value) => sum + value, 0)}
          </span>
        ) : (
          `${score.sum}`
        )}
      </Col>
      {isUpdated && (
        <>
          <Button
            type="primary"
            ghost
            disabled={unableToChangeScore}
            onClick={handleUpdate}
            style={{ marginLeft: '8px' }}
            // 不能被 tab focus
            tabIndex={-1}
          >
            更新
          </Button>
          <Button
            disabled={unableToChangeScore}
            onClick={handleReset}
            style={{ marginLeft: '8px' }}
            tabIndex={-1}
          >
            重置
          </Button>
        </>
      )}
      {!unableToChangeScore && !isUpdated && (
        <Button
          type="primary"
          disabled={unableToChangeScore}
          onClick={handleDeleteScore}
          style={{ marginLeft: '8px' }}
          tabIndex={-1}
        >
          删除
        </Button>
      )}
    </Row>
  );
};

const PlayerScore: React.FC<{
  player: DataPlayer;
}> = ({ player }) => {
  const scores = player.scores;
  const competitionData = useSelector(getSelectedCompetitionData);
  const dispatch = useDispatch<AppDispatch>();
  const handleScoreUpdate = async (updatedScore: any) => {
    await dispatch(
      updateCompetitorScore({
        teamId: player.consumerId,
        scoreId: updatedScore.id,
        scores: { values: updatedScore.values },
      })
    );
  };

  const handleAssignWinner = async () => {
    await dispatch(
      judgeCompetitionWinner({
        competitionId: competitionData?.id,
        winnerConsumerId: player.consumerId,
      })
    );
  };
  const unableToScoring =
    competitionData?.status !== 'started' ||
    (competitionData.status === 'started' &&
      (competitionData.type === 'knockOut'
        ? isString(competitionData?.winnerConsumerId) ||
          (competitionData?.knockoutSetConsumerId !== 'Both' &&
            competitionData?.knockoutSetConsumerId !== player.consumerId)
        : player.scores.flatMap((score) => score.values).length >=
          parseInt(competitionData.arrows)));
  const unableToChangeScore = competitionData?.status !== 'started';

  return (
    <List.Item style={{ display: 'flex', padding: '10px' }}>
      <Layout>
        <Layout.Sider style={{ background: 'none' }} width={400}>
          <Row justify="center" align="middle" style={{ height: '100%' }}>
            <List.Item.Meta
              avatar={
                <Avatar.Group>
                  {player.players.map((p) => (
                    <Avatar
                      key={'avatar' + p.consumerId}
                      src={p.profileImageUrl}
                      size={48}
                    />
                  ))}
                  <Avatar size={48}>{`${player.targetNum}号靶`}</Avatar>
                </Avatar.Group>
              }
              title={player.players.map((p) => p.name).join(' & ')}
              description={player.players
                .map((p) => p.merchantDisplayName)
                .join(' & ')}
            />
          </Row>
        </Layout.Sider>
        <Layout.Content>
          <Layout>
            <Layout.Content>
              <Col>
                {scores.map((score) => (
                  <ScoreRow
                    key={score.id}
                    unableToScoring={unableToScoring}
                    unableToChangeScore={unableToChangeScore}
                    score={score}
                    onUpdate={handleScoreUpdate}
                  />
                ))}
              </Col>
            </Layout.Content>
          </Layout>
        </Layout.Content>
        <Layout.Sider style={{ background: 'none' }} width={250}>
          <Row style={{ height: '100%' }}>
            <Col span={24}>
              <Row
                gutter={8}
                align="middle"
                justify="space-around"
                style={{ width: '100%' }}
              >
                <Statistic title="总分" value={player.scoreSum} />
                <Statistic title="X" value={player.scoreXCount} />
                <Statistic title="10" value={player.scoreTenCount} />
                {competitionData?.type === 'knockOut' &&
                  competitionData.bow !== 'compound' && (
                    <Statistic title="得分" value={player.knockOutPointSum} />
                  )}
                {competitionData?.type === 'knockOut' &&
                  competitionData?.status === 'ended' && (
                    <Statistic
                      title="胜负"
                      value={
                        competitionData.winnerConsumerId === player.consumerId
                          ? '胜'
                          : '败'
                      }
                      valueStyle={{
                        color:
                          competitionData.winnerConsumerId === player.consumerId
                            ? 'green'
                            : 'red',
                      }}
                    />
                  )}
              </Row>
            </Col>
            <Col span={24}>
              <Row
                gutter={8}
                align="middle"
                justify="center"
                style={{ height: '100%' }}
              >
                <Col>
                  <ScoringPopconfirm
                    player={player}
                    disabled={unableToScoring}
                  />
                </Col>
                {competitionData?.type == 'knockOut' && (
                  <Col>
                    <Button
                      type="primary"
                      danger
                      disabled={
                        competitionData.status === 'created' && unableToScoring
                      }
                      onClick={handleAssignWinner}
                    >
                      {'指定胜利'}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Layout.Sider>
      </Layout>
    </List.Item>
  );
};
const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];

const ScoringPopconfirm: React.FC<{
  player: DataPlayer;
  disabled: boolean;
  visible?: boolean;
}> = ({ player, disabled: ableToScoring, visible }) => {
  const competitionData = useSelector(getSelectedCompetitionData);
  const arrowsPerSet = parseInt(competitionData?.arrowsPerSet || '0');
  const isDistanceToTargetCenterSet =
    competitionData?.isDistanceToTargetCenterSet === true;
  const [scores, setScores] = React.useState<string[]>(
    isDistanceToTargetCenterSet ? [''] : new Array(arrowsPerSet).fill('')
  );
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = React.useState(visible);
  const [loading, setLoading] = React.useState(false);
  const notThrottle = useSelector(getAbleToScoring);

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  const confirm = async (e?: React.MouseEvent<HTMLElement>) => {
    setLoading(true);
    await dispatch(
      postCompetitorScore({
        teamId: player.consumerId,
        scores: { values: scores, competitionId: competitionData?.id },
      })
    );
    setLoading(false);
    setOpen(false);
  };

  const handleSimulateTab = () => {
    // 获取当前聚焦的元素
    const activeElement = document.activeElement as HTMLElement;

    // 获取页面中的所有可聚焦元素
    const focusableElements = Array.from(
      document.querySelectorAll(
        'input, button, select, textarea, a[href], [tabindex]:not([tabindex="-1"])'
      )
    ) as HTMLElement[];

    // 找到当前元素在 focusableElements 中的索引
    const currentIndex = focusableElements.indexOf(activeElement);

    // 找到下一个可聚焦元素的索引（循环到开头）
    const nextIndex = (currentIndex + 1) % focusableElements.length;

    // 聚焦到下一个元素
    focusableElements[nextIndex]?.focus();
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    const updatedScores = [...scores];

    if (isDistanceToTargetCenterSet) {
      // 限制为浮点数并保留三位小数
      if (/^\d*(\.\d{0,3})?$/.test(value)) {
        updatedScores[index] = value;
        setScores(updatedScores);
      }
    } else {
      // 多箭逻辑：允许输入 x 或者 0-11
      const normalizedValue = value.toLowerCase();
      if (
        normalizedValue === 'x' || // 允许输入 x
        normalizedValue === '' || // 允许清空
        (!isNaN(parseInt(normalizedValue, 10)) &&
          parseInt(normalizedValue, 10) >= 0 &&
          parseInt(normalizedValue, 10) <= 11)
      ) {
        updatedScores[index] = normalizedValue === 'x' ? '11' : normalizedValue; // 存储 'x' 为 '11'
        setScores(updatedScores);
        if (value && normalizedValue !== '1') {
          handleSimulateTab();
        }
      }
    }
  };

  const isValidInput = (value: string): boolean => {
    const isHalf = competitionData?.target.includes('half');
    const regularExpression =
      !isDistanceToTargetCenterSet && isHalf ? /^(?![2-4]$)\d+$/ : /^[0-9]$/;
    const isNumber = regularExpression.test(value);
    const isX = value.toLowerCase() === 'x';

    if (isDistanceToTargetCenterSet) {
      // 限制浮点数输入合法性
      if (!isNumber && value !== '.' && !allowedKeys.includes(value)) {
        return false;
      }
    } else {
      // 限制为数字或 x
      if (!isNumber && !isX && !allowedKeys.includes(value)) {
        return false;
      }
    }
    return true;
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    isValidInput(e.key) || e.preventDefault();
  };

  return (
    <Popconfirm
      icon
      onVisibleChange={(visible) => {
        setScores(
          isDistanceToTargetCenterSet ? [''] : new Array(arrowsPerSet).fill('')
        );
        setOpen(visible);
      }}
      visible={open}
      disabled={isString(competitionData?.winnerConsumerId)}
      title={
        <Form initialValues={scores}>
          请输入得分
          <Row>
            {scores.map((score, index) => (
              <Form.Item key={`form-${index}`}>
                <Input
                  key={`pop-confirm-input-${index}`}
                  style={{
                    textAlign: 'center',
                    width: isDistanceToTargetCenterSet ? '85px' : '45px', // 根据模式调整宽度
                    margin: '4px',
                    marginRight: '0',
                  }}
                  // 默认全选
                  onFocus={(e) => e.target.select()}
                  value={
                    isDistanceToTargetCenterSet
                      ? score
                      : valueToArcherScore(score)
                  }
                  onChange={(e) => handleInputChange(e, index)}
                  onKeyPress={handleKeyPress} // 限制非法字符
                  maxLength={isDistanceToTargetCenterSet ? 6 : 2} // 单箭模式允许浮点数，限制长度
                />
              </Form.Item>
            ))}
          </Row>
        </Form>
      }
      showCancel={false}
      okButtonProps={{
        onClick: confirm,
        loading: loading || !notThrottle,
      }}
    >
      <Button type="primary" ghost disabled={ableToScoring}>
        录入分数
      </Button>
    </Popconfirm>
  );
};
