import { Button as AntButton, ButtonProps } from 'antd';
import React from 'react';

const Button: React.FC<ButtonProps & React.RefAttributes<HTMLElement>> = ({
  ref,
  onClick,
  loading,
  children,
  ...props
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const handleClick = async (e: any) => {
    setLoading(true);
    onClick && (await onClick(e));
    setLoading(false);
  };
  return (
    <AntButton
      ref={ref}
      onClick={handleClick}
      loading={loading === undefined ? isLoading : loading}
      children={children}
      {...props}
    />
  );
};

export default Button;
